import React, { useEffect, useState, useRef } from "react"
import { Row, Col, Card, CardBody, Accordion, AccordionItem, AccordionHeader, AccordionBody, Button} from "reactstrap";
import MetaTags from 'react-meta-tags';
import Pagination from "components/Common/Pagination"
import { Link } from "react-router-dom";
import AddRemark from "./add-remark";
import useRemark from "./useRemark";
import { TableHeader } from "components/Datatable/index"
import useFullPageLoader from "../../components/Common/useFullPageLoader"
import pcl_icon from "../../assets/images/pcl_logo.png"
import track1 from "../../assets/images/Group-1.png"
import track2 from "../../assets/images/Group-2.png"
import track3 from "../../assets/images/Group-3.png"
import toastr from "toastr";
import { Bar, Doughnut } from "react-chartjs-2";
import Chart from "chart.js";
import { DASHBOARD_REPORT, HOLIDAY_LIST, GET_ALL_MASTERS, LEAD_SOURCE, VENDOR, REMARK_LIST, REMARK, PROJECTION_AMOUNT, BRAND_LEAD, TODAYS_ATTENDANCE, STAGE_LEAD } from "../../helpers/api_url_helper";
import { get, post } from "../../helpers/api_helper";

const MainDashboard = () => {
  // Store open accordion items as an array
  const [open, setOpen] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [remarkBrandId, setRemarkBrandId] = useState(null);
  const [remarkEmployeeId, setEmployeeBrandId] = useState(null);
  const [remarkData, setRemarkData] = useState(null);
  const { isShowingRemark, toggleRemark } = useRemark();
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [vendorTableData, setVendorTableData] = useState([])
  const [vendorCurrentPage, setVendorCurrentPage] = useState(1)
  const [VendorTotalItems, setVendorTotalItems] = useState(0)
  const [brandMaster, setBrandMaster] = useState([])
  const [searchQueries, setSearchQueries] = useState({});
  const [applicationTrackingSystem, setApplicationTrackingSystem] = useState({
    openings: 0,
    candidatesThisYear: 0,
    todayScreening: 0,
    todayInterview: 0,
    candidatesPlaced: 0,
    candidatesSourced: 0,
  });
  const [holidayList, setHolidayList] = useState([]);
  const [projectionAmount, setProjectionAmount] = useState([]);
  const [cityMaster, setCityMaster] = useState([]);
  const reloadBoard = () => {
    fetchRemarkList();
  }
  const [leadSources, setLeadSources] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [leadSourceCurrentPage, setLeadSourceCurrentPage] = useState(1);
  const [leadSourceFilters, setLeadSourceFilters] = useState({ brand: "", office_location: "" });
  const [projectionAmountFilters, setProjectionAmountFilters] = useState({ brand: "", office_location: "" });
  const leadSourcePageSize = 4; // Number of records per page
  const [remarkList, setRemarkList] = useState([]);
  const [totalRemark, setTotalRemark] = useState(0);
  const [remarkCurrentPage, setRemarkCurrentPage] = useState(1);
  const remarkPageSize = 5; // Number of records per page
  const [brandLeadLabel, setBrandLeadLabel] = useState([])
  const [brandLeadLabelId, setBrandLeadLabelId] = useState([])
  const [brandLeadData, setBrandLeadData] = useState([])
  const [brandLeadFilters, setBrandLeadFilters] = useState({ office_location: "" });
  const [todaysAttendancePresent, setTodaysAttendancePresent] = useState(0)
  const [todaysAttendanceAbsent, setTodaysAttendanceAbsent] = useState(0)
  const [stageLeadLabel, setStageLeadLabel] = useState([])
  const [stageLeadLabelId, setStageLeadLabelId] = useState([])
  const [stageLeadData, setStageLeadData] = useState([])
  const [stageLeadFilters, setStageLeadFilters] = useState({ brand: "", office_location: "" });
  
  useEffect(() => {
    get(DASHBOARD_REPORT).then(res => {
      if (res.status) {
        let data = res.data
        setApplicationTrackingSystem({
          openings: data.openings.total_opening,
          candidatesThisYear: data.candidates.total_candidates,
          todayScreening: data.today_screening,
          todayInterview: data.today_interviews.total_interviews,
          candidatesPlaced: data.candidates_placed.total_candidates_placed,
          candidatesSourced: data.cv_sources.total_cv_source,
          //   data.today_screening_candidates.total_candidates
          //   data.today_followup_candidates.total_candidates
          //   data.today_callmade
          //   data.today_followup
          //   data.candidates_placed.total_candidates_placed
          //   data.candidates_month_leaderboard.total_placed
        });
      }
    }).catch(err => {
      toastr.error(err)
    })
  }, []);
  
  useEffect(() => {
    get(HOLIDAY_LIST)
      .then((res) => {
        if (res.status) {
          setHolidayList(res.data); // Ensure data is properly structured
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
  }, []);
  
  useEffect(() => {
    get(GET_ALL_MASTERS, { params: { masters: 'brand_master' } }).then(res => {
      if (res.status) {
        let data = res.data;
        setBrandMaster(data.brand_master)
      }
    }).catch(err => {
      toastr.error(err)
    });
  }, []);
  
  useEffect(() => {
    get(GET_ALL_MASTERS, { params: { masters: 'office_location' } }).then(res => {
      if (res.status) {
        let data = res.data;
        setCityMaster(data.office_location)
      }
    }).catch(err => {
      toastr.error(err)
    });
  }, []);
  
  const fetchLeadSources = () => {
    post(LEAD_SOURCE, {
      length: leadSourcePageSize,
      start: leadSourceCurrentPage,
      filter: leadSourceFilters,
    })
      .then((res) => {
        if (res.status) {
          setLeadSources(res.data);
          setTotalLeads(res.total);
        }else{
          setLeadSources([]);
          setTotalLeads(0);
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
  };
  
  useEffect(() => {
    fetchLeadSources();
  }, [leadSourceCurrentPage, leadSourceFilters]);
  
  const fetchProjectionAmount = () => {
    post(PROJECTION_AMOUNT, {
      filter: projectionAmountFilters,
    })
      .then((res) => {
        if (res.status) {
          setProjectionAmount(res.data);
        }else{
          setProjectionAmount([]);
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
  };
  
  useEffect(() => {
    fetchProjectionAmount();
  }, [projectionAmountFilters]);

  const handleLeadSourceFilterChange = (e, filterType) => {
    setLeadSourceFilters((prev) => ({ ...prev, [filterType]: e.target.value }));
    setLeadSourceCurrentPage(1); // Reset pagination when filters change
  };
  
  const handleProjectionAmountFilterChange = (e, filterType) => {
    setProjectionAmountFilters((prev) => ({ ...prev, [filterType]: e.target.value }));
  };

  const handleLeadSourcePrev = () => {
    if (leadSourceCurrentPage > 1) setLeadSourceCurrentPage(leadSourceCurrentPage - 1);
  };

  const handleLeadSourceNext = () => {
    if (leadSourceCurrentPage * leadSourcePageSize < totalLeads) setLeadSourceCurrentPage(leadSourceCurrentPage + 1);
  };
  
  const fetchRemarkList = () => {
    post(REMARK_LIST, {
      length: remarkPageSize,
      start: remarkCurrentPage
    })
      .then((res) => {
        if (res.status) {
          setRemarkList(res.data);
          setTotalRemark(res.total);
        }else{
          setRemarkList([]);
          setTotalRemark(0);
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
  };
  
  useEffect(() => {
    fetchRemarkList();
  }, [remarkCurrentPage]);
  
  const handleRemarkPrev = () => {
    if (remarkCurrentPage > 1) setRemarkCurrentPage(remarkCurrentPage - 1);
  };

  const handleRemarkNext = () => {
    if (remarkCurrentPage * remarkPageSize < totalRemark) setRemarkCurrentPage(remarkCurrentPage + 1);
  };
  
  const vendorPageSize = 6;
    useEffect(() => {
      showLoader();
      const params = { length: vendorPageSize, start: vendorCurrentPage };
      post(VENDOR, params)
        .then(res => {
          if (res) {
            setVendorTableData(res.data);
            setVendorTotalItems(res.total);
            hideLoader();
          }
        })
        .catch(err => {
          hideLoader();
          toastr.error(err);
        });
    }, [vendorCurrentPage]); 
  
  const toggle = (id) => {
    setOpen((prevState) =>
      prevState.includes(id) ? prevState.filter((item) => item !== id) : [...prevState, id]
    );
  };

  const modalRemark = (brand_id, employee_id) => {

    setRemarkBrandId(brand_id);
    setEmployeeBrandId(employee_id);
    // Fetch the remark data for the specific lead
    get(REMARK, { params: { brand_id: brand_id, employee_id: employee_id } })
      .then(res => {
        if (res.status) {
          // Assuming res.data contains the remark data for the lead
          const remarkData = res.data;
          
          // Toggle the remark modal and pass the remark data
          setRemarkData(remarkData); 
          toggleRemark(!isShowingRemark);
        }else{
          setRemarkData('');
          toggleRemark(!isShowingRemark);
        }
      })
      .catch(err => {
        toastr.error("Failed to fetch remark", err);
        setRemarkData('');
        toggleRemark(!isShowingRemark);
      });
  };
  
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.toLocaleString("en-GB", { month: "long" });
    const year = date.getFullYear();
    const suffix = day % 10 === 1 && day !== 11 ? "st" :
                   day % 10 === 2 && day !== 12 ? "nd" :
                   day % 10 === 3 && day !== 13 ? "rd" : "th";
    return `${day}${suffix} ${month} ${year}`;
  };
  
  const formatDateTime = (dateTimeStr) => {
    const date = new Date(dateTimeStr);

    // Extract date components
    const day = date.getDate();
    const month = date.toLocaleString("en-GB", { month: "long" });
    const year = date.getFullYear();
    const suffix = day % 10 === 1 && day !== 11 ? "st" :
                   day % 10 === 2 && day !== 12 ? "nd" :
                   day % 10 === 3 && day !== 13 ? "rd" : "th";

    // Extract time components
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format

    return `${day}${suffix} ${month} ${year}, ${formattedHours}:${minutes} ${ampm}`;
  };

  const visibleData = showAll ? holidayList : holidayList.slice(0, 7);
  
  const vendorTableColum = [
    { name: "Brands", field: "brand", sortable: false },
    { name: "Category", field: "category", sortable: false },
    { name: "City", field: "city", sortable: false },
    { name: "Regd. Vendors", field: "reg_vendor", sortable: false },
  ]
  
 // Modify the draw function for fully rounded bars (top & bottom)
  Chart.elements.Rectangle.prototype.draw = function () {
    const ctx = this._chart.ctx;
    const vm = this._view;
    let left, right, top, bottom, radius;
    const borderWidth = vm.borderWidth || 0;

    left = vm.x - vm.width / 2;
    right = vm.x + vm.width / 2;
    top = vm.y;
    bottom = vm.base;

    if (top === bottom) {
      return; // Prevent drawing if the height is zero
    }

    let barHeight = bottom - top;
    let minRadius = 7; // Minimum height for applying rounded corners

    if (barHeight < minRadius * 2) {
      // If the bar is too small, remove rounding
      radius = 0;
    } else {
      radius = Math.min(10, (right - left) / 2);
    }

    ctx.beginPath();
    ctx.fillStyle = vm.backgroundColor;
    ctx.strokeStyle = vm.borderColor;
    ctx.lineWidth = borderWidth;

    // Draw bar with or without rounded corners
    ctx.moveTo(left, bottom - radius);
    if (radius > 0) {
      ctx.quadraticCurveTo(left, bottom, left + radius, bottom);
      ctx.lineTo(right - radius, bottom);
      ctx.quadraticCurveTo(right, bottom, right, bottom - radius);
      ctx.lineTo(right, top + radius);
      ctx.quadraticCurveTo(right, top, right - radius, top);
      ctx.lineTo(left + radius, top);
      ctx.quadraticCurveTo(left, top, left, top + radius);
    } else {
      ctx.lineTo(right, bottom);
      ctx.lineTo(right, top);
      ctx.lineTo(left, top);
    }
    ctx.closePath();

    ctx.fill();
    if (borderWidth) {
      ctx.stroke();
    }
  };

  // Calculate max lead count dynamically
  const maxBrandLeadCount = Math.max(...brandLeadData);
  // Determine stepSize dynamically based on maxBrandLeadCount
  const brandLeadDynamicStepSize = Math.ceil(maxBrandLeadCount / 5 / 100) * 100 || 100; // Adjusts step size in multiples of 100
  const brand_lead_data = {
    labels: brandLeadLabel,
    datasets: [
      {
        label: "Leads",
        data: brandLeadData,
        backgroundColor: "#33DCCF",
        hoverBackgroundColor: "#0FB2A6",
        barThickness: 40,
        categoryPercentage: 0.6,
        barPercentage: 0.9,
      },
    ],
  };

  const brand_lead_options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      backgroundColor: "#ffffff",
      titleFontSize: 14,
      titleFontColor: "#000",
      bodyFontSize: 16,
      bodyFontColor: "#000",
      xPadding: 12,
      yPadding: 12,
      borderColor: "#ccc",
      borderWidth: 1,
      displayColors: false,
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: { display: false },
          ticks: {
            autoSkip: false, // Prevent skipping labels
            maxRotation: 0,  // Keep text horizontal
            minRotation: 0,
            fontSize: 12,
            fontColor: "#6B7280",
            callback: function(value, index, values) {
              const ctx = this.chart.ctx;
              ctx.font = "12px Arial"; // Ensure consistent font size
              const textWidth = ctx.measureText(value).width; // Measure label width
          
              if (textWidth > 15) { // Adjust threshold as needed
                let words = value.split(" ");
                let lines = [];
                let line = "";
          
                words.forEach(word => {
                  let testLine = line.length > 0 ? line + " " + word : word;
                  if (ctx.measureText(testLine).width > 90) { // Adjust max width
                    lines.push(line);
                    line = word;
                  } else {
                    line = testLine;
                  }
                });
          
                lines.push(line); // Push last line
                return lines; // Return array to show text on multiple lines
              }
              
              return value;
            }
          },                 
        },
      ],
      yAxes: [
        {
          gridLines: { color: "#E5E7EB" },
          ticks: {
            beginAtZero: true,
            stepSize: brandLeadDynamicStepSize, // Apply dynamic step size
            max: Math.ceil(maxBrandLeadCount / brandLeadDynamicStepSize) * brandLeadDynamicStepSize, // Ensure max is aligned
            fontColor: "#6B7280",
            fontSize: 12,
          },
        },
      ],
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0]._index;
        const selectedBrandId = brandLeadLabelId[index]; // Get brand ID
        const selectedLocation = brandLeadFilters.office_location; // Get office_location filter
    
        // Redirect to new page with query params
        window.location.href = `/leads?brand_id=${encodeURIComponent(selectedBrandId)}&office_location=${encodeURIComponent(selectedLocation)}`;
      }
    },    
  };  
  
  // Calculate max lead count dynamically
  const maxStageLeadCount = Math.max(...stageLeadData);
  // Determine stepSize dynamically based on maxBrandLeadCount
  const stageLeadDynamicStepSize = Math.ceil(maxStageLeadCount / 5 / 100) * 100 || 100; // Adjusts step size in multiples of 100
  const brand_stage_data = {
    labels: stageLeadLabel,
    datasets: [
      {
        label: "Leads",
        data: stageLeadData,
        backgroundColor: [
          "#3D7AE1",
          "#5DDC9F",
          "#E483A3",
          "#33DCCF",
          "#0FB2A6",
          "#B1B1B1",
          "#D0BC92",
          "#0FB235",
        ],
        hoverBackgroundColor: [
          "#3466bc",
          "#56b487",
          "#bf6c87",
          "#0fb2a6",
          "#0b847b",
          "#8d8c8c",
          "#ac9b78",
          "#0b8427",
        ],
        barThickness: 40,
        categoryPercentage: 0.6,
        barPercentage: 0.9,
      },
    ],
  };

  const brand_stage_options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      backgroundColor: "#ffffff",
      titleFontSize: 14,
      titleFontColor: "#000",
      bodyFontSize: 16,
      bodyFontColor: "#000",
      xPadding: 12,
      yPadding: 12,
      borderColor: "#ccc",
      borderWidth: 1,
      displayColors: false,
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: { display: false },
          ticks: {
            autoSkip: false, // Prevent skipping labels
            maxRotation: 0,  // Keep text horizontal
            minRotation: 0,
            fontSize: 12,
            fontColor: "#6B7280",
            callback: function(value, index, values) {
              const ctx = this.chart.ctx;
              ctx.font = "12px Arial"; // Ensure consistent font size
              const textWidth = ctx.measureText(value).width; // Measure label width
          
              if (textWidth > 15) { // Adjust threshold as needed
                let words = value.split(" ");
                let lines = [];
                let line = "";
          
                words.forEach(word => {
                  let testLine = line.length > 0 ? line + " " + word : word;
                  if (ctx.measureText(testLine).width > 90) { // Adjust max width
                    lines.push(line);
                    line = word;
                  } else {
                    line = testLine;
                  }
                });
          
                lines.push(line); // Push last line
                return lines; // Return array to show text on multiple lines
              }
              
              return value;
            }
          },                 
        },
      ],
      yAxes: [
        {
          gridLines: { color: "#E5E7EB" },
          ticks: {
            beginAtZero: true,
            stepSize: stageLeadDynamicStepSize, // Apply dynamic step size
            max: Math.ceil(maxStageLeadCount / stageLeadDynamicStepSize) * stageLeadDynamicStepSize, // Ensure max is aligned
            fontColor: "#6B7280",
            fontSize: 12,
          },
        },
      ],
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0]._index;
        const selectedStageId = stageLeadLabelId[index]; // Get stage ID
        const selectedLocation = stageLeadFilters.office_location; // Get office_location filter
        const selectedBrand = stageLeadFilters.brand; // Get brand filter
    
        // Redirect to new page with query params
        window.location.href = `/leads?stage_id=${encodeURIComponent(selectedStageId)}&brand_id=${encodeURIComponent(selectedBrand)}&office_location=${encodeURIComponent(selectedLocation)}`;
      }
    }, 
  };  
  
  const today_attendance_data = {
    labels: ["Present", "Absent"],
    datasets: [
      {
        data: [todaysAttendancePresent, todaysAttendanceAbsent], // Example attendance data
        backgroundColor: ["#33DCCF", "#B3CCF6"], // Colors for Present & Absent
        hoverBackgroundColor: ["#0FB2A6", "#95abd1"], // Hover effect colors
      },
    ],
  };

  const today_attendance_options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 55, // Controls the thickness of the donut
    legend: {
      display: true,
      position: "bottom",
      labels: {
        fontSize: 14,
        padding: 15,
        usePointStyle: true, // Converts the box into a circle
      },
    },
    tooltips: {
      enabled: true,
      backgroundColor: "#ffffff",
      titleFontSize: 14,
      titleFontColor: "#000",
      bodyFontSize: 16,
      bodyFontColor: "#000",
      xPadding: 12,
      yPadding: 12,
      borderColor: "#ccc",
      borderWidth: 1,
      displayColors: false,
    },
  };
  
  const styles = {
    container: {
      width: "100%",
      height: "10px",
      backgroundColor: "#b0c4ff", // Light blue (remaining progress)
      borderRadius: "20px",
      overflow: "hidden",
      marginBottom: "50px",
    },
    progress: {
      height: "100%",
      backgroundColor: "#12d8cf", // Teal (filled progress)
      borderRadius: "20px",
      transition: "width 0.5s ease-in-out",
    },
  };
  
  const fetchTodaysAttendance = () => {
    get(TODAYS_ATTENDANCE)
      .then((res) => {
        if (res.status) {
          setTodaysAttendancePresent(res.data.present_employee);
          setTodaysAttendanceAbsent(res.data.absent_employee);
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
  };
  
  useEffect(() => {
    fetchTodaysAttendance();
  }, []);
  
  const fetchBrandLead = () => {
    post(BRAND_LEAD, {
      filter: brandLeadFilters,
    })
      .then((res) => {
        if (res.status) {
          const labels = res.data.map((item) => item.brand_name);
          const labels_id = res.data.map((item) => item.brand_id);
          const data = res.data.map((item) => Number(item.lead_count)); // Convert to number
  
          setBrandLeadLabel(labels);
          setBrandLeadLabelId(labels_id);
          setBrandLeadData(data);
        }else{
          setBrandLeadLabel([]);
          setBrandLeadLabelId([]);
          setBrandLeadData([]);
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
  };
  
  useEffect(() => {
    fetchBrandLead();
  }, [brandLeadFilters]);
  
  const handleBrandLeadFilterChange = (e, filterType) => {
    setBrandLeadFilters((prev) => ({ ...prev, [filterType]: e.target.value }));
  };

  const fetchStageLead = () => {
    post(STAGE_LEAD, {
      filter: stageLeadFilters,
    })
      .then((res) => {
        if (res.status) {
          const labels = res.data.map((item) => item.stage_name);
          const labels_id = res.data.map((item) => item.stage_id);
          const data = res.data.map((item) => Number(item.lead_count)); // Convert to number
  
          setStageLeadLabel(labels);
          setStageLeadLabelId(labels_id);
          setStageLeadData(data);
        }else{
          setStageLeadLabel([]);
          setStageLeadLabelId([]);
          setStageLeadData([]);
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
  };
  
  useEffect(() => {
    fetchStageLead();
  }, [stageLeadFilters]);
  
  const handleStageLeadFilterChange = (e, filterType) => {
    setStageLeadFilters((prev) => ({ ...prev, [filterType]: e.target.value }));
  };

  const handleSearch = (value, itemValue) => {
    setSearchQueries((prev) => ({
      ...prev,
      [itemValue]: value,
    }));
  };
  
  return (
    <React.Fragment>
      <div className="page-content main-dashboard mb-5">
        <MetaTags>
          <title>Dashboard | PARTY CRUISERS LTD</title>
        </MetaTags>
        <div className="container-fluid">
          <h4 className="m-0 title">Brand Leads</h4>
          <Card className="sticky-main-header">
            <CardBody>
              <div className="row">
                <div className="col-12" style={{ width: "100%", overflowX: "auto" }}>
                  <select className="form-select" onChange={(e) => handleBrandLeadFilterChange(e, "office_location")}>
                    <option value="">City</option>
                    {cityMaster.map((option, index) => (
                      <option key={option.value || index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div className="chart-container" style={{ minWidth: "930px", height: "300px", margin: "28px" }}>
                    <Bar data={brand_lead_data} options={brand_lead_options} />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <h4 className="m-0 title">Brand Wise Stages</h4>
          <Card className="sticky-main-header">
            <CardBody>
              <div className="row">
                <div className="col-12" style={{ width: "100%", overflowX: "auto" }}>
                  <div className="d-flex gap-3">
                    <select className="form-select" onChange={(e) => handleStageLeadFilterChange(e, "brand")}>
                      <option value="">Brands</option>
                      {brandMaster.map((option, index) => (
                        <option key={option.value || index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <select className="form-select" onChange={(e) => handleStageLeadFilterChange(e, "office_location")}>
                      <option value="">City</option>
                      {cityMaster.map((option, index) => (
                        <option key={option.value || index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="chart-container" style={{ minWidth: "930px", height: "300px", margin: "28px" }}>
                    <Bar data={brand_stage_data} options={brand_stage_options} />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className="row">
            <div className="col-xl-8">
              <div className="row">
                <div className="col-xl-6">
                  <h4 className="m-0 title">Remarks</h4>
                  <Card className="sticky-main-header">
                    <CardBody className="accordion-card-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="mt-2 mb-3">
                            {/* Main Accordion */}
                            <Accordion open={open} toggle={toggle} className="accordin-card">
                              {remarkList.length > 0 ? (
                                remarkList.map((item, index) => (
                                  <AccordionItem key={index}>
                                    <AccordionHeader targetId={item.value}>
                                      <div className="accordion-title-div">
                                        <span className="accordion-title">{item.label}</span>
                                        {item.last_note_date && (
                                          <span className="accordion-date">
                                            Notes added on {formatDateTime(item.last_note_date)}
                                          </span>
                                        )}
                                      </div>
                                    </AccordionHeader>
                                    <AccordionBody accordionId={item.value}>
                                      {/* Search Input */}
                                      <input
                                        type="text"
                                        className="form-control mb-3"
                                        placeholder="Search employees..."
                                        value={searchQueries[item.value] || ""}
                                        onChange={(e) => handleSearch(e.target.value, item.value)}
                                      />

                                      {/* Nested Accordion - Filtered List */}
                                      {item.employee_list
                                        .filter((subItem) =>
                                          subItem.label.toLowerCase().includes((searchQueries[item.value] || "").toLowerCase())
                                        )
                                        .map((subItem, subIndex) => (
                                          <div
                                            style={{ cursor: "pointer" }}
                                            key={subIndex}
                                            onClick={() => modalRemark(item.value, subItem.value)}
                                            data-employee-id={`${subItem.value}`}
                                            data-brand-id={`${item.value}`}
                                            className="tags-option"
                                            id={`tags-option-${subItem.value}`}
                                          >
                                            <div className="modal-btn-title-div">
                                              <span className="modal-btn-title">
                                                {subItem.label} <span>{subItem.note_count}</span>
                                              </span>
                                              {subItem.last_note_date && (
                                                <span className="accordion-date">
                                                  Notes added on {formatDateTime(subItem.last_note_date)}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        ))}
                                    </AccordionBody>
                                  </AccordionItem>
                                ))
                              ) : (
                                <p className="text-center mt-3">No remark found.</p>
                              )}
                            </Accordion>
                            <div style={{marginTop:'20px'}}>
                              <Button className="pre-btn" type="button" onClick={handleRemarkPrev} disabled={remarkCurrentPage === 1}>
                                Previous
                              </Button>
                              <Button className="next-btn" type="button" onClick={handleRemarkNext} disabled={remarkCurrentPage * remarkPageSize >= totalRemark}>
                                Next
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-xl-6">
                  <h4 className="m-0 title">Today’s Attendance</h4>
                  <Card className="sticky-main-header">
                    <CardBody>
                      <div className="row">
                        <div className="col-12">
                          <div style={{ height: "400px" }}>
                            <Doughnut data={today_attendance_data} options={today_attendance_options} />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-xl-12">
                  <h4 className="m-0 title">Vendors</h4>
                  <Card className="sticky-main-header">
                    <CardBody>
                      <div className="row">
                        <div className="col-12">
                          <div className="mt-2">
                            <div className="table-responsive">
                              <table className="table table-striped vendor-table">
                                <TableHeader
                                  headers={vendorTableColum}
                                />
                                <tbody className="">
                                  {loader && (
                                    <tr>
                                      <th colSpan={vendorTableColum.length}>
                                        <h4 className="text-center">Loading...</h4>
                                      </th>
                                    </tr>
                                  )}
                                  {!loader && vendorTableData.length === 0 && (
                                    <tr>
                                      <th colSpan={vendorTableColum.length}>
                                        <h4 className="text-center">No data found</h4>
                                      </th>
                                    </tr>
                                  )}
                                  {!loader &&
                                    vendorTableData.map((value, index) => (
                                      <tr key={++index}>
                                        <td>{value.brand}</td>
                                        <td>{value.category_name}</td>
                                        <td>{value.city_name}</td>
                                        <td>{value.vendor_count}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                            <Row className="justify-content-center">
                              <Col className="col-auto">
                                <Pagination
                                  className="pagination-bar"
                                  currentPage={vendorCurrentPage}
                                  totalCount={VendorTotalItems}
                                  pageSize={vendorPageSize}
                                  onPageChange={page => setVendorCurrentPage(page)}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <h4 className="m-0 title">Projection Amount</h4>
              <Card className="sticky-main-header">
                <CardBody>
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex gap-3">
                          <select className="form-select" onChange={(e) => handleProjectionAmountFilterChange(e, "brand")}>
                            <option value="">Brands</option>
                            {brandMaster.map((option, index) => (
                              <option key={option.value || index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <select className="form-select" onChange={(e) => handleProjectionAmountFilterChange(e, "office_location")}>
                            <option value="">City</option>
                            {cityMaster.map((option, index) => (
                              <option key={option.value || index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        {projectionAmount.total_event_cost !== null && projectionAmount.total_event_cost !== "" && (
                          <div className="info-tip">
                              <p>
                                Total Cost: ₹ {projectionAmount.total_event_cost} <br />
                                Less Advance 1: ₹ {projectionAmount.total_advance} <br />
                                Balance: ₹ {projectionAmount.total_balance}
                              </p>
                          </div>
                        )}
                      </div>
                      {projectionAmount.total_event_cost !== null && projectionAmount.total_event_cost !== "" ? (
                       <div>
                          <div className="d-flex justify-content-between">
                            <span className="projection_amount_value">₹ {projectionAmount.total_advance}</span>
                            <span className="projection_amount_total">₹ {projectionAmount.total_balance}</span>
                          </div>
                          {projectionAmount.total_event_cost > 0 && (
                            <div style={styles.container}>
                              <div 
                                style={{ 
                                  ...styles.progress, 
                                  width: `${( projectionAmount.total_advance / projectionAmount.total_event_cost) * 100}%`
                                }}
                              ></div>
                            </div>
                           )}
                       </div>
                      ): (
                        <p className="text-center mt-3">No amount found.</p>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
              <h4 className="m-0 title">Holiday List</h4>
              <Card className="sticky-main-header">
                <CardBody>
                  <div className="row">
                    <div className="col-12">
                      <div className="mt-2 mb-3">
                        <div className="holiday-card">
                          {visibleData.map((item) => (
                            <div className="holiday-title-div" key={item.id}>
                              <span className="holiday-title">{item.name}</span>
                              <span className="holiday-date">
                                {item.location}, {formatDate(item.date)}
                              </span>
                            </div>
                          ))}
                        </div>
                        {!showAll && holidayList.length > 6 && (
                          <Button className="view-all-btn" type="button" onClick={() => setShowAll(true)}>
                            View All
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="m-0 title2">Lead Source</h4>
                <div className="d-flex gap-2">
                  <select className="form-select" onChange={(e) => handleLeadSourceFilterChange(e, "brand")}>
                    <option value="">Brands</option>
                    {brandMaster.map((option, index) => (
                      <option key={option.value || index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <select className="form-select" onChange={(e) => handleLeadSourceFilterChange(e, "office_location")}>
                    <option value="">City</option>
                    {cityMaster.map((option, index) => (
                      <option key={option.value || index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {leadSources.length > 0 ? (
                leadSources.map((item, index) => (
                  <Card className="sticky-main-header lead-source-main-card" key={index}>
                    <CardBody>
                      <div className="row">
                        <div className="col-12">
                          <div className="lead-source-card">
                            <div className="d-flex gap-3 align-items-center">
                              <img className="lead-source-img" src={item.icon || pcl_icon} alt={item.name} />
                              <div className="lead-source-title-div">
                                <span className="lead-source-count">{item.lead_count} Leads</span>
                                <span className="lead-source-name">{item.name}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ))
              ) : (
                <p className="text-center mt-3">No leads found.</p>
              )}

              <div style={{ marginTop: "4px" }} className="mb-3 text-center">
                <Button className="pre-btn" type="button" onClick={handleLeadSourcePrev} disabled={leadSourceCurrentPage === 1}>
                  Previous
                </Button>
                <Button className="next-btn" type="button" onClick={handleLeadSourceNext} disabled={leadSourceCurrentPage * leadSourcePageSize >= totalLeads}>
                  Next
                </Button>
              </div>
            </div>
            <div className="col-xl-8">
              <h4 className="m-0 title2 mb-3 mt-2">Application Tracking System</h4>
              <div className="row">
                <div className="col-xl-4">
                  <Card className="sticky-main-header">
                    <CardBody>
                      <div className="row">
                        <div className="col-12">
                          <div className="lead-source-card">
                            <div className="d-flex gap-3 align-items-center">
                              <img className="lead-source-img" src={track1} alt="" />
                              <div className="lead-source-title-div">
                                <span className="lead-source-name">Openings</span>
                                <span className="lead-source-count">{applicationTrackingSystem.openings}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-xl-4">
                  <Card className="sticky-main-header">
                    <CardBody>
                      <div className="row">
                        <div className="col-12">
                          <div className="lead-source-card">
                            <div className="d-flex gap-3 align-items-center">
                              <img className="lead-source-img" src={track2} alt="" />
                              <div className="lead-source-title-div">
                                <span className="lead-source-name">Candidates this Year</span>
                                <span className="lead-source-count">{applicationTrackingSystem.candidatesThisYear}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-xl-4">
                  <Card className="sticky-main-header">
                    <CardBody>
                      <div className="row">
                        <div className="col-12">
                          <div className="lead-source-card">
                            <div className="d-flex gap-3 align-items-center">
                              <img className="lead-source-img" src={track3} alt="" />
                              <div className="lead-source-title-div">
                                <span className="lead-source-name">Today’s screening</span>
                                <span className="lead-source-count">{applicationTrackingSystem.todayScreening}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-xl-4">
                  <Card className="sticky-main-header">
                    <CardBody>
                      <div className="row">
                        <div className="col-12">
                          <div className="lead-source-card">
                            <div className="d-flex gap-3 align-items-center">
                              <img className="lead-source-img" src={track1} alt="" />
                              <div className="lead-source-title-div">
                                <span className="lead-source-name">Today’s Interview</span>
                                <span className="lead-source-count">{applicationTrackingSystem.todayInterview}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-xl-4">
                  <Card className="sticky-main-header">
                    <CardBody>
                      <div className="row">
                        <div className="col-12">
                          <div className="lead-source-card">
                            <div className="d-flex gap-3 align-items-center">
                              <img className="lead-source-img" src={track2} alt="" />
                              <div className="lead-source-title-div">
                                <span className="lead-source-name">Candidate Placed</span>
                                <span className="lead-source-count">{applicationTrackingSystem.candidatesPlaced}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-xl-4">
                  <Card className="sticky-main-header">
                    <CardBody>
                      <div className="row">
                        <div className="col-12">
                          <div className="lead-source-card">
                            <div className="d-flex gap-3 align-items-center">
                              <img className="lead-source-img" src={track3} alt="" />
                              <div className="lead-source-title-div">
                                <span className="lead-source-name">Candidate Sourced</span>
                                <span className="lead-source-count">{applicationTrackingSystem.candidatesSourced}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
          {isShowingRemark === true &&
            <AddRemark
              isShowing={isShowingRemark}
              reloadBoard={reloadBoard}
              hide={toggleRemark}
              brand_id={remarkBrandId}
              employee_id={remarkEmployeeId}
              remarkData={remarkData}
            />
          }    
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainDashboard;