import React from "react";
import { Row, Col, Input, Label, Modal, Button } from "reactstrap";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import { ADD_REMARK } from "../../helpers/api_url_helper";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import toastr from "toastr";


class AddRemark extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brand_id: props.brand_id || "",
      employee_id: props.employee_id || "",
      remark: '',
      submit: false,
      isShowing: false,
      formSubmitSuccess: false,
      remarkData: props.remarkData
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.isMountedComponent = false;
  }

  componentDidMount() {
    this.isMountedComponent = true;
  }

  componentWillUnmount() {
    this.isMountedComponent = false;
  }

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };  
  
  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {     
      const formData = new FormData();      
      formData.append("brand_id", this.state.brand_id);
      formData.append("employee_id", this.state.employee_id);
      formData.append("remark", this.state.remark);
      this.setState({ submit: true });
      axios({
        method: "post",
        url: `${process.env.REACT_APP_APIURL}${ADD_REMARK}`,
        data: formData,
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      })
        .then((response) => {
          if (response.status && this.isMountedComponent) {
            this.setState({ 
              submit: false, 
              isShowing: false,
              brand_id: "",
              employee_id: '',
              remark: '',
            });
            toastr.success("Remark added successfully.");
            this.props.hide(false);
            this.props.reloadBoard(true);
          }
        })
        .catch((err) => {
          if (this.isMountedComponent) {
            toastr.error(err.message);
            this.setState({ submit: false });
            alert(err.message);
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  
  formatDateTime = (dateTimeStr) => {
    const date = new Date(dateTimeStr);

    // Extract date components
    const day = date.getDate();
    const month = date.toLocaleString("en-GB", { month: "long" });
    const year = date.getFullYear();
    const suffix = day % 10 === 1 && day !== 11 ? "st" :
                   day % 10 === 2 && day !== 12 ? "nd" :
                   day % 10 === 3 && day !== 13 ? "rd" : "th";

    // Extract time components
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format

    return `${day}${suffix} ${month} ${year}, ${formattedHours}:${minutes} ${ampm}`;
  };

  render() {
    const { formSubmitSuccess } = this.state;
    return (
      <React.Fragment>
        <Modal size="md" isOpen={this.props.isShowing} toggle={this.props.hide} centered>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Remark</h5>
            <button
              id="close_model"
              type="button"
              onClick={this.props.hide}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {this.state.remarkData.length > 0 ? (
              this.state.remarkData.map((item, index) => (
                
                <Row  key={index}>
                  <Col md="12" className="mb-3" style={ {borderBottom: '1px solid #ced4da',paddingBottom: '10px'}}>
                    <div className="candidate-meta">
                      <span>Date Time: <span className="text-dark text-primary">{this.formatDateTime(item.created_at)}</span></span>
                    </div>
                    <div className="candidate-meta">
                      <span>Remark: <span className="text-dark text-primary">{item.notes}</span></span>
                    </div>
                  </Col>
                </Row>
              ))
            ) : (
              <p className="text-center"></p>
            )}                         
            <h4>Add New Remark</h4>
            {formSubmitSuccess === false && (
              <form onSubmit={this.handleFormSubmit}>
                <Row>
                  <Col md="12" className="mb-3">
                    <Label htmlFor="remark">
                      Remark<span className="text-danger">*</span>
                    </Label>
                    <textarea
                      className="form-control"
                      style={{ width: "100%" }}
                      onChange={this.handleInput}
                      name="remark"
                      rows={6}
                      value={this.state.remark}
                    />
                    {this.validator.message("remark", this.state.remark, "required")}
                  </Col>
                </Row>
                <div className="d-flex flex-wrap gap-2 justify-content-end">
                  <Button color="primary" type="submit" disabled={this.state.submit}>
                    {this.state.submit === true ? "Please wait..." : "Submit Data"}
                  </Button>
                </div>
              </form>
            )}
            {formSubmitSuccess === true && (
              <div className="text-center mb-4">
                <div className="avatar-md mx-auto mb-4">
                  <div className="avatar-title bg-light rounded-circle text-primary h1">
                    <i className="bx bx-user-check"></i>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <h4 className="text-primary">Success !</h4>
                    <p className="text-muted font-size-14 mb-4">Remark add successful.</p>
                    <button
                      type="button"
                      id="button-addon2"
                      onClick={(e) => this.setState({ formSubmitSuccess: false })}
                      className="btn btn-primary"
                    >
                      Add New remark
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

AddRemark.propTypes = {
  attribs: PropTypes.any,
  isShowing: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  reloadBoard: PropTypes.func.isRequired,
};

export default AddRemark;
