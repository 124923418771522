import React, { useState, useEffect, useMemo } from "react"
import {
  Button,
  CardTitle,
  Col,
  Row,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
} from "reactstrap"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import toastr, { error } from "toastr"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  createWorkDetail,
  editWorkDetail,
  fetchDepartment,
  fetchDesignation,
  fetchEmployeeType,
  fetchProbationPeriod,
  fetchReportingManager,
  fetchSubDepartment,
  fetchWorkDetail,
  fetchWorkJobTitle,
  setOpenWorkDetailModal,
  setSubdepartment,
} from "store/workDetail/workDetailSlice"

export const WorkDetailForm = () => {
  const dispatch = useDispatch()

  const {
    tableRowData,
    openWorkDetailModal,
    employeeTypeData,
    departmentData,
    subDepartmentData,
    designationData,
    probationData,
    managerData,
    currentPage,
    workJobTitleData,
  } = useSelector(state => state.workDetail)

  const [loading, setLoading] = useState(false)
  const { userId } = useSelector(state => state.onBoarding)
  const [subDepartmnetValue, setSubdepartmentValue] = useState("")
  console.log(tableRowData, "Verify Table Row Data over here")
  const toggle = () => {
    dispatch(setOpenWorkDetailModal(false))
  }

  const handleSubDepartment = async department_id => {
    await setLoading(true)
    try {
      await dispatch(fetchSubDepartment(department_id))
    } catch (error) {
      await setLoading(false)
    } finally {
      await setLoading(false)
    }
  }

  useMemo(() => {
    setSubdepartmentValue(tableRowData?.sub_department_master_id)
  }, [tableRowData, openWorkDetailModal])

  useEffect(() => {
    dispatch(fetchEmployeeType())
    dispatch(fetchDepartment())
    dispatch(fetchDesignation())
    dispatch(fetchProbationPeriod())
    dispatch(fetchReportingManager())
    dispatch(fetchWorkJobTitle())
  }, [])

  const validationSchema = Yup.object().shape({
    employment_type_master_id: Yup.number().required("Required"),
    department_master_id: Yup.number().when("is_current", {
      is: false,
      then: Yup.number().required("Required"),
      otherwise: Yup.number().notRequired(),
    }),
    sub_department_master_id: Yup.number().when("is_current", {
      is: false,
      then: Yup.number().required("Required"),
      otherwise: Yup.number().notRequired(),
    }),
    reporting_manager_employee_id: Yup.number().when("is_current", {
      is: false,
      then: Yup.number().required("Required"),
      otherwise: Yup.number().notRequired(),
    }),
    designation_id: Yup.number().required("Required"),
    probition_period_id: Yup.number().when("is_current", {
      is: false,
      then: Yup.number().required("Required"),
      otherwise: Yup.number().notRequired(),
    }),
    notice_period_id: Yup.number().when("is_current", {
      is: false,
      then: Yup.number().required("Required"),
      otherwise: Yup.number().notRequired(),
    }),
    emp_no: Yup.string().required("Required"),
    job_title: Yup.string().when("job_role_id", {
      is: "0",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
    job_role_id: Yup.string().required("Required"),
    company_name: Yup.string().when("is_current", {
      is: true,
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
    department_name: Yup.string().when("department_master_id", {
      is: "0",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
    sub_department_name: Yup.string().when("sub_department_master_id", {
      is: "0",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
    designation_name: Yup.string().when("designation_id", {
      is: "0",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
  })

  return (
    <div className="d-flex justify-content-center">
        <Row className="profile-container">
          <Col xl="12">
            <Card>
              <CardBody>
                <CardTitle className="header-text">{`${
                  tableRowData?.id ? "Edit" : "Add"
                } Work Detail`}</CardTitle>
                <hr className="underline" />
                <Formik
                  initialValues={{
                    emp_no:
                      Object.keys(tableRowData).length > 0
                        ? tableRowData?.emp_no
                        : "",
                    employment_type_master_id:
                      Object.keys(tableRowData).length > 0
                        ? tableRowData?.employment_type_master_id
                        : "",
                    department_master_id:
                      Object.keys(tableRowData).length > 0
                        ? tableRowData?.department_master_id
                        : "",
                    sub_department_master_id:
                      Object.keys(tableRowData).length > 0
                        ? tableRowData?.sub_department_master_id
                        : "",
                    designation_id:
                      Object.keys(tableRowData).length > 0
                        ? tableRowData?.designation_id
                        : "",
                    job_title:
                      Object.keys(tableRowData).length > 0
                        ? tableRowData?.job_title
                        : "",
                    probition_period_id:
                      Object.keys(tableRowData).length > 0
                        ? tableRowData?.probition_period_id
                        : "",
                    notice_period_id:
                      Object.keys(tableRowData).length > 0
                        ? tableRowData?.notice_period_id
                        : "",
                    reporting_manager_employee_id:
                      Object.keys(tableRowData).length > 0
                        ? tableRowData?.reporting_manager_employee_id
                        : "",
                        is_current: tableRowData?.is_current == 0 ? false : true,
                        department_name:
                      Object.keys(tableRowData).length > 0
                        ? tableRowData?.department_name
                        : "",
                    sub_department_name:
                      Object.keys(tableRowData).length > 0
                        ? tableRowData?.sub_department_name
                        : "",
                    designation_name:
                      Object.keys(tableRowData).length > 0
                        ? tableRowData?.designation_name
                        : "",
                    company_name:
                      Object.keys(tableRowData).length > 0
                        ? tableRowData?.company_name
                        : "",
                    job_role_id:
                      Object.keys(tableRowData).length > 0
                        ? tableRowData?.job_role_id
                        : "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    values.is_current = values?.is_current ? 1 : 0
                    const payload = {
                      ...values,
                      employee_id: userId,
                    }
                    const response =
                      Object.keys(tableRowData).length > 0
                        ? await dispatch(
                            editWorkDetail({ id: tableRowData?.id, data: payload })
                          )
                        : await dispatch(createWorkDetail(payload))
                    try {
                      if (response.payload?.status == "success") {
                        toastr.success(response?.payload?.data?.data?.message)
                      } else {
                        toastr.error(response?.payload?.message)
                      }
                    } catch (error) {
                      toastr.error("Something Went Wrong Please Try Latter")
                    }
                    await dispatch(
                      fetchWorkDetail({ id: userId, length: 10, start: currentPage })
                    )
                    await dispatch(setOpenWorkDetailModal(false))
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    isSubmitting,
                    errors,
                    touched,
                    dirty,
                  }) => (
                    <Form>
                      {console.log(errors, "Verify Errors")}
                      <Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <Input
                              type="checkbox"
                              name="is_current"
                              className="form-check-input"
                              defaultChecked={values.is_current}
                              onChange={e => {
                                setFieldValue("is_current", e.target.checked)
                              }}
                            />
                            <Label className="mx-2 labels" htmlFor="is_current">
                              Is previous work detail
                            </Label>
                          </Col>
                        </Row>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="emp_no">
                            Employee ID <span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="emp_no"
                            placeholder="Employee ID"
                            type="text"
                            className={`form-control ${
                              touched.emp_no && errors.emp_no ? "is-invalid" : ""
                            }`}
                          />
                          {touched.emp_no && errors.emp_no && (
                            <div className="text-danger">{errors.emp_no}</div>
                          )}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="job_role_id">
                            Job Role
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="job_role_id"
                            value={workJobTitleData.find(
                              option => option.value == values.job_role_id
                            )}
                            onChange={option =>
                              setFieldValue("job_role_id", Number(option.value))
                            }
                            options={workJobTitleData}
                            classNamePrefix="select2-selection"
                          />
                          {touched.job_role_id && errors.job_role_id && (
                            <div className="text-danger">{errors.job_role_id}</div>
                          )}
                        </Col>
                        {values?.job_role_id == 0 && (
                          <Col md="4" className="mb-3">
                            <Label className="labels" htmlFor="job_title">
                              Job Title<span className="text-danger">*</span>
                            </Label>
                            <Field
                              name="job_title"
                              placeholder="Job Title"
                              type="text"
                              className={`form-control ${
                                touched.emp_no && errors.emp_no ? "is-invalid" : ""
                              }`}
                            />
                            {touched.job_title && errors.job_title && (
                              <div className="text-danger">{errors.job_title}</div>
                            )}
                          </Col>
                        )}
                        <Col md="4" className="mb-3">
                          <Label
                            className="labels"
                            htmlFor="reporting_manager_employee_id"
                          >
                            Reporting Manager
                            {!values?.is_current && (
                              <span className="text-danger">*</span>
                            )}
                          </Label>
                          <Select
                            name="reporting_manager_employee_id"
                            value={managerData.find(
                              option =>
                                option.value == values.reporting_manager_employee_id
                            )}
                            onChange={option =>
                              setFieldValue(
                                "reporting_manager_employee_id",
                                Number(option.value)
                              )
                            }
                            options={managerData}
                            classNamePrefix="select2-selection"
                          />
                          {touched.reporting_manager_employee_id &&
                            errors.reporting_manager_employee_id && (
                              <div className="text-danger">
                                {errors.reporting_manager_employee_id}
                              </div>
                            )}
                        </Col>
                        {!values?.is_current && (
                          <Col md="4" className="mb-3">
                            <Label className="labels" htmlFor="probition_period_id">
                              Probation Period
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              name="probition_period_id"
                              value={probationData.find(
                                option => option.value == values.probition_period_id
                              )}
                              onChange={option =>
                                setFieldValue(
                                  "probition_period_id",
                                  Number(option.value)
                                )
                              }
                              options={probationData}
                              classNamePrefix="select2-selection"
                            />
                            {touched.probition_period_id &&
                              errors.probition_period_id && (
                                <div className="text-danger">
                                  {errors.probition_period_id}
                                </div>
                              )}
                          </Col>
                        )}
                        {!values?.is_current && (
                          <Col md="4" className="mb-3">
                            <Label className="labels" htmlFor="notice_period_id">
                              Notice Period
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              name="notice_period_id"
                              value={probationData.find(
                                option => option.value == values.notice_period_id
                              )}
                              onChange={option =>
                                setFieldValue(
                                  "notice_period_id",
                                  Number(option.value)
                                )
                              }
                              options={probationData}
                              classNamePrefix="select2-selection"
                            />
                            {touched.notice_period_id &&
                              errors.notice_period_id && (
                                <div className="text-danger">
                                  {errors.notice_period_id}
                                </div>
                              )}
                          </Col>
                        )}
                        <Col md="4" className="mb-3">
                          <Label
                            className="labels"
                            htmlFor="employment_type_master_id"
                          >
                            Employment Type <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="employment_type_master_id"
                            value={employeeTypeData.find(
                              option =>
                                option.value == values.employment_type_master_id
                            )}
                            onChange={option =>
                              setFieldValue(
                                "employment_type_master_id",
                                Number(option.value)
                              )
                            }
                            options={employeeTypeData}
                            classNamePrefix="select2-selection"
                          />
                          {touched.employment_type_master_id &&
                            errors.employment_type_master_id && (
                              <div className="text-danger">
                                {errors.employment_type_master_id}
                              </div>
                            )}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="department_master_id">
                            Department
                            {!values?.is_current && (
                              <span className="text-danger">*</span>
                            )}
                          </Label>
                          <Select
                            name="department_master_id"
                            value={departmentData.find(
                              option => option.value == values.department_master_id
                            )}
                            onChange={option => {
                              setFieldValue(
                                "department_master_id",
                                Number(option.value)
                              )
                              setSubdepartmentValue("")
                              setFieldValue("sub_department_master_id", "")
                            }}
                            options={departmentData}
                            classNamePrefix="select2-selection"
                          />
                          {touched.department_master_id &&
                            errors.department_master_id && (
                              <div className="text-danger">
                                {errors.department_master_id}
                              </div>
                            )}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label
                            className="labels"
                            htmlFor="sub_department_master_id"
                          >
                            Sub Department
                            {!values?.is_current && (
                              <span className="text-danger">*</span>
                            )}
                          </Label>
                          <Select
                            name="sub_department_master_id"
                            value={
                              (subDepartmnetValue?.length > 0 &&
                                subDepartmentData.find(
                                  option => option.value == subDepartmnetValue
                                )) ||
                              subDepartmnetValue
                            }
                            isDisabled={values?.department_master_id == ""}
                            onChange={option => {
                              setFieldValue(
                                "sub_department_master_id",
                                Number(option.value)
                              )
                              setSubdepartmentValue(option?.value)
                            }}
                            onFocus={() => {
                              handleSubDepartment(values.department_master_id)
                            }}
                            onMenuOpen={() => {
                              handleSubDepartment(values.department_master_id)
                            }}
                            isLoading={loading}
                            options={subDepartmentData}
                            classNamePrefix="select2-selection"
                          />
                          {touched.sub_department_master_id &&
                            errors.sub_department_master_id && (
                              <div className="text-danger">
                                {errors.sub_department_master_id}
                              </div>
                            )}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="designation_id">
                            Designation <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="designation_id"
                            value={designationData.find(
                              option => option.value == values.designation_id
                            )}
                            onChange={option => {
                              setFieldValue("designation_id", Number(option.value))
                            }}
                            options={designationData}
                            classNamePrefix="select2-selection"
                          />
                          {touched.designation_id && errors.designation_id && (
                            <div className="text-danger">{errors.designation_id}</div>
                          )}
                        </Col>
                        {values.is_current && (
                          <Col md="4" className="mb-3">
                            <Label className="labels" htmlFor="company_name">
                              Company Name <span className="text-danger">*</span>
                            </Label>
                            <Field
                              name="company_name"
                              placeholder="Company Name"
                              type="text"
                              className={`form-control ${
                                touched.company_name && errors.company_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {touched.company_name && errors.company_name && (
                              <div className="text-danger">{errors.company_name}</div>
                            )}
                          </Col>
                        )}
                        {values.department_master_id == "0" && (
                          <Col md="4" className="mb-3">
                            <Label className="labels" htmlFor="department_name">
                              Department Name <span className="text-danger">*</span>
                            </Label>
                            <Field
                              name="department_name"
                              placeholder="Department Name"
                              type="text"
                              className={`form-control ${
                                touched.department_name && errors.department_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {touched.department_name && errors.department_name && (
                              <div className="text-danger">
                                {errors.department_name}
                              </div>
                            )}
                          </Col>
                        )}
                        {values.sub_department_master_id == "0" && (
                          <Col md="4" className="mb-3">
                            <Label className="labels" htmlFor="sub_department_name">
                              Sub Department Name{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Field
                              name="sub_department_name"
                              placeholder="Sub Department Name"
                              type="text"
                              className={`form-control ${
                                touched.sub_department_name &&
                                errors.sub_department_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {touched.sub_department_name &&
                              errors.sub_department_name && (
                                <div className="text-danger">
                                  {errors.sub_department_name}
                                </div>
                              )}
                          </Col>
                        )}
                        {values.designation_id == "0" && (
                          <Col md="4" className="mb-3">
                            <Label className="labels" htmlFor="designation_name">
                              Designation Name <span className="text-danger">*</span>
                            </Label>
                            <Field
                              name="designation_name"
                              placeholder="Designation Name"
                              type="text"
                              className={`form-control ${
                                touched.designation_name && errors.designation_name
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {touched.designation_name && errors.designation_name && (
                              <div className="text-danger">
                                {errors.designation_name}
                              </div>
                            )}
                          </Col>
                        )}
                      </Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <button
                          className="d-flex align-items-center btn btn-md btn-outline-secondary"
                          type="reset"
                          onClick={toggle}
                        >
                          <i className="dripicons-cross mx-1 d-flex" />
                          Cancel
                        </button>
                        <Button
                          color="primary"
                          type="submit"
                          disabled={isSubmitting || !dirty}
                        >
                          {Object.keys(tableRowData).length > 0 ? (
                            <>{isSubmitting ? "Updating..." : "Update"}</>
                          ) : (
                            <>{isSubmitting ? "Submitting..." : "Submit"}</>
                          )}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </div>
  )
}
