import React, { Redirect } from "react-router-dom"
// import Home from "../pages/Landing/index"
// Profile
import UserProfile from "../pages/Authentication/user-profile"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import MainDashboard from "../pages/MainDashboard/index"
// Masters ----------------------
import Department from "../pages/Masters/Department/index"
import DepartmentAdd from "../pages/Masters/Department/add"
import DepartmentEdit from "../pages/Masters/Department/edit"

import SubDepartment from "../pages/Masters/Sub-Department/index"
import SubDepartmentAdd from "../pages/Masters/Sub-Department/add"
import SubDepartmentEdit from "../pages/Masters/Sub-Department/edit"

import EmploymentType from "../pages/Masters/Employment-Type/index"
import EmploymentTypeAdd from "../pages/Masters/Employment-Type/add"
import EmploymentTypeEdit from "../pages/Masters/Employment-Type/edit"

import Designations from "../pages/Masters/Designations/index"
import DesignationsAdd from "../pages/Masters/Designations/add"
import DesignationsEdit from "../pages/Masters/Designations/edit"

import ProbationPeriod from "../pages/Masters/Probation-Period/index"
import ProbationPeriodAdd from "../pages/Masters/Probation-Period/add"
import ProbationPeriodEdit from "../pages/Masters/Probation-Period/edit"

import Expenses from "../pages/Masters/Expenses/index"
import ExpensesAdd from "../pages/Masters/Expenses/add"
import ExpensesEdit from "../pages/Masters/Expenses/edit"

import Holiday from "../pages/Masters/Holiday/index"
import HolidayAdd from "../pages/Masters/Holiday/add"
import HolidayEdit from "../pages/Masters/Holiday/edit"

import Country from "../pages/Masters/Country/index"
import CountryAdd from "../pages/Masters/Country/add"
import CountryEdit from "../pages/Masters/Country/edit"

import State from "../pages/Masters/State/index"
import StateAdd from "../pages/Masters/State/add"
import StateEdit from "../pages/Masters/State/edit"

import OfficeLocation from "../pages/Masters/Office-Location/index"
import OfficeLocationAdd from "../pages/Masters/Office-Location/add"
import OfficeLocationEdit from "../pages/Masters/Office-Location/edit"

import City from "../pages/Masters/City/index"
import CityAdd from "../pages/Masters/City/add"
import CityEdit from "../pages/Masters/City/edit"

import JobRole from "../pages/Masters/Job-Role/index"
import JobRoleAdd from "../pages/Masters/Job-Role/add"
import JobRoleEdit from "../pages/Masters/Job-Role/edit"

import LocationList from "../pages/Masters/Location/index"
import LocationAdd from "../pages/Masters/Location/add"
import LocationEdit from "../pages/Masters/Location/edit"

import Skills from "../pages/Masters/Skills/index"
import SkillsAdd from "../pages/Masters/Skills/add"
import SkillsEdit from "../pages/Masters/Skills/edit"

import Qualifications from "../pages/Masters/Qualifications/index"
import QualificationAdd from "../pages/Masters/Qualifications/add"
import QualificationEdit from "../pages/Masters/Qualifications/edit"
import ClientSource from "../pages/Masters/Client-Source"
import ClientSourceAdd from "../pages/Masters/Client-Source/add"
import ClientSourceEdit from "../pages/Masters/Client-Source/edit"
import CandidateSource from "../pages/Masters/Candidate-Source"
import CandidateSourceAdd from "../pages/Masters/Candidate-Source/add"
import CandidateSourceEdit from "../pages/Masters/Candidate-Source/edit"
import ClientStatus from "../pages/Masters/Client-Status"
import ClientStatusAdd from "../pages/Masters/Client-Status/add"
import ClientStatusEdit from "../pages/Masters/Client-Status/edit"
import RequirementStatus from "../pages/Masters/Requirement-Status"
import RequirementStatusAdd from "../pages/Masters/Requirement-Status/add"
import RequirementStatusEdit from "../pages/Masters/Requirement-Status/edit"

import SalaryCurrency from "../pages/Masters/Salary-Currency"
import SalaryCurrencyAdd from "../pages/Masters/Salary-Currency/add"
import SalaryCurrencyEdit from "../pages/Masters/Salary-Currency/edit"

// Leads
import Leads from "../pages/Leads"
import LeadsAdd from "../pages/Leads/add-lead"
import LeadOverview from "pages/Leads/lead-detail"
import LeadTodaysToDo from "pages/Leads/todays-to-do"
//TandA
import TandA from "../pages/TandA"
// crm
import AssignTarget from "../pages/TandA/assignTarget"
import AssignTargetEidt from "../pages/TandA/assignTargetEdit"
import ManageVendor from "../pages/Crm-Vendor"
import ManageVendorAdd from "../pages/Crm-Vendor/add"
import ManageVendorEdit from "../pages/Crm-Vendor/edit"
import VendorCategory from "../pages/Crm-Master/Vendor-Category"
import VendorCategoryAdd from "../pages/Crm-Master/Vendor-Category/add"
import VendorCategoryEdit from "../pages/Crm-Master/Vendor-Category/edit"
import LeadSource from "../pages/Crm-Master/Lead-Source"
import LeadSourceAdd from "../pages/Crm-Master/Lead-Source/add"
import LeadSourceEdit from "../pages/Crm-Master/Lead-Source/edit"
// Brands
import Brands from "../pages/Crm-Master/Brands"
import BrandsAdd from "../pages/Crm-Master/Brands/add"
import BrandsEdit from "../pages/Crm-Master/Brands/edit"
// Business Category
import BusinessCategory from "../pages/Crm-Master/Business-Category"
import BusinessCategoryAdd from "../pages/Crm-Master/Business-Category/add"
import BusinessCategoryEdit from "../pages/Crm-Master/Business-Category/edit"
// Element Sheet
import EsCategory from "../pages/Crm-Master/Es-Category"
import EsElements from "../pages/Crm-Master/Es-Elements"
import EsCategoryAdd from "../pages/Crm-Master/Es-Category/add"
import EsCategoryEdit from "../pages/Crm-Master/Es-Category/edit"
import EsElementsAdd from "../pages/Crm-Master/Es-Elements/add"
import EsElementsEdit from "../pages/Crm-Master/Es-Elements/edit"
// Clients ---------------------
import Clients from "../pages/Clients"
import ClientAdd from "../pages/Clients/add"
import Openings from "../pages/Openings"
import AddJob from "../pages/Openings/Create-Job/index"
import Board from "../pages/Openings/Stage/board"
import Candidates from "../pages/Candidate"
import Openinglist from "../pages/Openings/Candidate-list"
import JobDetail from "../pages/Job-detail"
import UserEdit from "../pages/Users-Onboarding/edit"
import ClientEdit from "../pages/Clients/edit"
import NoticePeriod from "../pages/Masters/Notice-Period"
import NoticePeriodAdd from "../pages/Masters/Notice-Period/add"
import NoticePeriodEdit from "../pages/Masters/Notice-Period/edit"
import EditJob from "../pages/Openings/Edit-Job"
import CandidateOverview from "pages/Candidate/candidate-detail"
import OpeningDetail from "pages/Openings/opening-detail"
import Reports from "pages/Reports"
import Blogs from "pages/Blogs"
import BlogAdd from "pages/Blogs/add"
import BlogEdit from "pages/Blogs/edit"
import ChangePassword from "pages/Authentication/ChangePassword"
import {
  AttendanceApproval,
  AttendanceApprovalEdit,
} from "pages/ManageAttendence"
import {
  ApplyLeaveForm,
  LeaveLogs,
  PaidLeaveData,
  PaidLeaveForm,
  PaidLeaveSummary,
} from "pages/ApplyLeave"
import { ProfileView } from "pages/ProfileView"
// import { WorkDetailForm } from "pages/WorkDetail"
import { ResignationInfoFrom } from "pages/ResignationInfo"
// import { OtherDocumentForm } from "pages/OtherDocument"
// import AttendancePenaltyRule from "pages/ManageAttendence/AttendancePenalty"
// import AttendanceRule from "pages/ManageAttendence/AttendanceRule"
// import AttendanceApprovalList from "pages/ManageAttendence/AttendanceApproval"
import DeductionRewards, {
  DeductionRewardList,
} from "pages/Masters/DeductionReward"
import ExpenseRulesEdit from "pages/Masters/Expense-Rule/edit"
import ExpenseRulesAdd from "pages/Masters/Expense-Rule/add"
import ExpenseRule from "pages/Masters/Expense-Rule"
import ExpenseRuleMapping from "pages/Masters/Expense-Rule/Expense-Rule-mapping"
import ExpenseRuleMappingAdd from "pages/Masters/Expense-Rule/Expense-Rule-mapping/add"
import ExpenseRuleMappingEdit from "pages/Masters/Expense-Rule/Expense-Rule-mapping/edit"
import { ExpenseRewardList } from "pages/Masters/ExpenseReward"
import ExpenseRewardsAdd from "pages/Masters/ExpenseReward/add"
import ExpenseRewardsEdit from "pages/Masters/ExpenseReward/edit"
import ExpenseDocument, {
  ExpenseDocumentList,
} from "pages/Masters/ExpenseReward/ExpenseDocument"
import { DeductionRewardRuleList } from "pages/Masters/DeductionRewardRule"
import { DeductionMappingList } from "pages/Masters/DeductionMapping"
import { ExpenseRuleList } from "pages/Masters/ExpenseRule"
import { ExpenseMappingList } from "pages/Masters/ExpenseMapping"
import { UsersForm, UsersView } from "pages/UsersView"
import ResetPassword from "pages/Authentication/ResetPassword"
import LeaveBalance from "pages/ApplyLeave/LeaveBalance"
import UserChangePassword from "pages/Authentication/UserChangePassword"
import Permission from "pages/Masters/Permission/Permission"
import { AttendanceRuleForm } from "pages/ManageAttendence/AttendanceRule"
import { AttendanceRuleList } from "pages/ManageAttendence/AttendanceRule"
import {
  AttendanceEmployeeList,
  AttendanceLogs,
} from "pages/ManageAttendence/AttendanceLogs"
import Attendences from "pages/ManageAttendence/Attendence"
import { LeaveRuleForm, LeaveRuleList } from "pages/ApplyLeave/LeaveRules"
import { LeaveLogList } from "pages/ApplyLeave/LeaveLog/LeaveLogList"
import LeaveApprovalList from "pages/ApplyLeave/LeaveApproval/LeaveApprovalList"
import Leaves from "pages/ApplyLeave/Leaves"
import { CompanyOverview } from "pages/CompanyOverview/CompanyOverview"
import { SalaryList } from "pages/Masters/Salary"
import { EmployeeAccessoryList } from "pages/Masters/EmployeeAccessory"
import { AssetTypeList } from "pages/Masters/AssetType"
import Expense from "pages/Masters/ManageExpense/Expense"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/main-dashboard", component: MainDashboard },
  // Masters ----------
  { path: "/department", component: Department, requiredPermission:"Department_list" },
  { path: "/department/add", component: DepartmentAdd, requiredPermission:"add_department" },
  { path: "/department/edit/:id", component: DepartmentEdit, requiredPermission:"edit_department" },
  { path: "/sub-department", component: SubDepartment, requiredPermission:"SubDepartment_list" },
  { path: "/sub-department/add", component: SubDepartmentAdd, requiredPermission:'add_subdepartment' },
  { path: "/sub-department/edit/:id", component: SubDepartmentEdit, requiredPermission:'edit_subdepartment' },
  { path: "/employment-type", component: EmploymentType, requiredPermission:"EmploymentType_list" },
  { path: "/employment-type/add", component: EmploymentTypeAdd, requiredPermission: 'add_empType' },
  { path: "/employment-type/edit/:id", component: EmploymentTypeEdit, requiredPermission: 'edit_empType' },
  { path: "/designations", component: Designations, requiredPermission:"Designations_list" },
  { path: "/designations/add", component: DesignationsAdd, requiredPermission: 'add_designation' },
  { path: "/designations/edit/:id", component: DesignationsEdit, requiredPermission: 'edit_designation' },
  { path: "/probation-period", component: ProbationPeriod, requiredPermission:"ProbationPeriod_list" },
  { path: "/probation-period/add", component: ProbationPeriodAdd, requiredPermission: 'add_probation' },
  { path: "/probation-period/edit/:id", component: ProbationPeriodEdit, requiredPermission: 'edit_probation' },
  { path: "/holiday", component: Holiday, requiredPermission:"Holiday_list" },
  { path: "/holiday/add", component: HolidayAdd, requiredPermission:"add_holiday" },
  { path: "/holiday/edit/:id", component: HolidayEdit, requiredPermission:"edit_holiday" },
  { path: "/country", component: Country, requiredPermission:"Country_list" },
  { path: "/country/add", component: CountryAdd, requiredPermission: 'add_country' },
  { path: "/country/edit/:id", component: CountryEdit, requiredPermission: 'edit_country' },
  { path: "/state", component: State, requiredPermission:"State_list" },
  { path: "/state/add", component: StateAdd, requiredPermission: 'add_state' },
  { path: "/state/edit/:id", component: StateEdit, requiredPermission: 'edit_state' },
  { path: "/office-location", component: OfficeLocation},
  { path: "/office-location/add", component: OfficeLocationAdd },
  { path: "/office-location/edit/:id", component: OfficeLocationEdit },
  { path: "/city", component: City, requiredPermission:"City_list" },
  { path: "/city/add", component: CityAdd, requiredPermission: 'add_city' },
  { path: "/city/edit/:id", component: CityEdit, requiredPermission: 'edit_city' },
  { path: "/job-roles", component: JobRole, requiredPermission:"JobRole_list" },
  { path: "/job-roles/add", component: JobRoleAdd, requiredPermission: 'add_job_role' },
  { path: "/job-roles/edit/:id", component: JobRoleEdit, requiredPermission: 'edit_job_role' },
  { path: "/skills", component: Skills, requiredPermission:"Skills_list" },
  { path: "/skills/add", component: SkillsAdd, requiredPermission: 'add_job_skill' },
  { path: "/skills/edit/:id", component: SkillsEdit, requiredPermission: 'edit_job_skill' },
  { path: "/client-source", component: ClientSource, requiredPermission:"ClientSource_list" },
  { path: "/client-source/add", component: ClientSourceAdd, requiredPermission: 'add_client_source' },
  { path: "/client-source/edit/:id", component: ClientSourceEdit, requiredPermission: 'edit_client_source' },
  { path: "/candidate-source", component: CandidateSource, requiredPermission:"CandidateSource_list" },
  { path: "/candidate-source/add", component: CandidateSourceAdd, requiredPermission: 'add_candidate_saurce' },
  { path: "/candidate-source/edit/:id", component: CandidateSourceEdit, requiredPermission: 'edit_candidate_saurce' },
  { path: "/client-status", component: ClientStatus, requiredPermission:"ClientStatus_list" },
  { path: "/client-status/add", component: ClientStatusAdd, requiredPermission: 'add_client_status' },
  { path: "/client-status/edit/:id", component: ClientStatusEdit, requiredPermission: 'edit_client_status' },
  { path: "/requirement-status", component: RequirementStatus, requiredPermission:"RequirementStatus_list" },
  { path: "/requirement-status/add", component: RequirementStatusAdd, requiredPermission: 'add_requirement_status' },
  { path: "/requirement-status/edit/:id", component: RequirementStatusEdit, requiredPermission: 'edit_requirement_status' },
  { path: "/salary-currency", component: SalaryCurrency, requiredPermission:"SalaryCurrency_list" },
  { path: "/salary-currency/add", component: SalaryCurrencyAdd, requiredPermission: 'add_salary_carurrency' },
  { path: "/salary-currency/edit/:id", component: SalaryCurrencyEdit, requiredPermission: 'edit_salary_carurrency' },
  { path: "/notice-period", component: NoticePeriod, requiredPermission:"NoticePeriod_list" },
  { path: "/notice-period/add", component: NoticePeriodAdd, requiredPermission: 'add_notice' },
  { path: "/notice-period/edit/:id", component: NoticePeriodEdit, requiredPermission: 'edit_notice' },
  { path: "/expense-reward", component: Expense, requiredPermission: "expense_logs" },
  { path: "/expense-rules", component: ExpenseRuleList },
  { path: "/expense-mapping", component: ExpenseMappingList },
  // { path: "/expenses", component: Expenses, requiredPermission: "expenses_category" },
  { path: "/expenses/add", component: ExpensesAdd, },
  { path: "/expenses/edit/:id", component: ExpensesEdit },
  { path: "/deduction-reward-rules", component: DeductionRewardRuleList },
  { path: "/deduction-rewards-mapping", component: DeductionMappingList },
  // User Boarding-Demo ---------------
  { path: "/user-boarding", component: UsersView },
  { path: "/user-boarding/add", component: UsersForm, requiredPermission:"add_user" },
  // { path: "/user-attendance-rule", component: AttendanceRuleList },
  // { path: "/user-leave-rules", component: LeaveRuleList},
  { path: "/user-attendance-logs", component: Attendences },
  { path: "/user-employee-attendance-logs", component: AttendanceEmployeeList },
  { path: "/user-attendance-rule/:id", component: AttendanceRuleForm, requiredPermission: "edit_attendance_rule" },
  { path: "/user-leave-rule/:id", component: LeaveRuleForm, requiredPermission: "edit_leave_rule" },
  // { path: "/user-attendance-approval", component: AttendanceApproval },
  { path: "/user-attendance-approval/edit", component: AttendanceApprovalEdit },
  { path: "/user-leave-logs", component: Leaves },
  { path: "/user-leave-approval", component: LeaveApprovalList },
  { path: "/user-leave-balance", component: LeaveBalance },
  // { path: "/user-apply-leave", component: ApplyLeaveForm },
  // { path: "/user-leave-rules", component: PaidLeaveData },
  { path: "/user-paid-create", component: PaidLeaveForm },
  { path: "/user-boarding/edit/:id", component: UserEdit },
  // locations
  { path: "/location", component: LocationList },
  { path: "/location/add", component: LocationAdd },
  { path: "/location/edit/:id", component: LocationEdit },
  { path: "/qualifications", component: Qualifications },
  { path: "/qualifications/add", component: QualificationAdd },
  { path: "/qualifications/edit/:id", component: QualificationEdit },
  // { path: "/candidate-status", component: CandidateStatus },
  // { path: "/candidate-status/add", component: CandidateStatusAdd },
  // { path: "/candidate-status/edit/:id", component: CandidateStatusEdit },
  // Clients ---------------------
  { path: "/clients", component: Clients },
  { path: "/clients/add", component: ClientAdd },
  { path: "/clients/edit/:id", component: ClientEdit },
  // Openings --------------------
  { path: "/openings", component: Openings },
  { path: "/openings/add", component: AddJob },
  { path: "/openings/edit/:id", component: EditJob },
  { path: "/openings/board/:id", component: Board },
  { path: "/openings/list/:id", component: Openinglist },
  { path: "/openings/detail/:id", component: OpeningDetail },
  //  notice period --------------
  // Candidate -------------------
  { path: "/candidates", component: Candidates },
  { path: "/candidate/:id", component: CandidateOverview },
  // Leads -------------------
  { path: "/leads", component: Leads },
  { path: "/lead/:id", component: LeadOverview },
  { path: "/leads/add", component: LeadsAdd },
  { path: "/leads/todays-to-do", component: LeadTodaysToDo },
  // T&A
  { path: "/target-acheievment", component: TandA },
  //Assign Target
  { path: "/assign-target", component: AssignTarget },
  { path: "/assign-target/edit/:id", component: AssignTargetEidt },
  // Vendor
  { path: "/manage-vendor", component: ManageVendor , requiredPermission:"manage_vendor_list" },
  { path: "/manage-vendor/add", component: ManageVendorAdd , requiredPermission:"add_manage_vendor" },
  { path: "/manage-vendor/edit/:id", component: ManageVendorEdit, requiredPermission:"edit_manage_vendor" },
  // Vendor Category Master
  { path: "/vendor-category", component: VendorCategory , requiredPermission:"vendor_category_list" },
  { path: "/vendor-category/add", component: VendorCategoryAdd , requiredPermission:"add_vendor_category" },
  { path: "/vendor-category/edit/:id", component: VendorCategoryEdit, requiredPermission:"edit_vendor_category" },
  // Leads Source
  { path: "/leads-source", component: LeadSource  , requiredPermission:"leads_source_list" },
  { path: "/leads-source/add", component: LeadSourceAdd, requiredPermission:"add_leads_source" },
  { path: "/leads-source/edit/:id", component: LeadSourceEdit , requiredPermission:"edit_leads_source" },
  // brands
  { path: "/brands", component: Brands, requiredPermission:"brands_list" },
  { path: "/brand/add", component: BrandsAdd, requiredPermission:"add_brands" },
  { path: "/brand/edit/:id", component: BrandsEdit, requiredPermission:"edit_brands" },
  // business category
  { path: "/business-category", component: BusinessCategory, requiredPermission:"business_category_list" },
  { path: "/business-category/add", component: BusinessCategoryAdd, requiredPermission:"add_business_category" },
  { path: "/business-category/edit/:id", component: BusinessCategoryEdit, requiredPermission:"edit_business_category" },
  // Element Sheet Master
  // ES Category
  { path: "/element-sheet-master/es-category", component: EsCategory, requiredPermission:"es_category_list" },
  { path: "/element-sheet-master/es-category/add", component: EsCategoryAdd , requiredPermission:"add_es_category" },
  { path: "/element-sheet-master/es-category/edit/:id", component: EsCategoryEdit , requiredPermission:"edit_es_category" },
  // ES Element
  { path: "/element-sheet-master/es-elements", component: EsElements , requiredPermission:"es_element_list" },
  { path: "/element-sheet-master/es-elements/add", component: EsElementsAdd, requiredPermission:"add_es_element" },
  { path: "/element-sheet-master/es-elements/edit/:id", component: EsElementsEdit , requiredPermission:"edit_es_element" },
  // Element Sheet
  // { path: "/element-sheet/add", component: ElementSheetAdd },
  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/profile/view", component: ProfileView },
  { path: "/overview", component: CompanyOverview },

  //  Reports  --------------------
  { path: "/reports", component: Reports },
  //  Blogs
  { path: "/blogs", component: Blogs },
  { path: "/blogs/add", component: BlogAdd },
  { path: "/blogs/edit/:id", component: BlogEdit },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  // Rewards and expense

  { path: "/DeductionRewards", component: DeductionRewardList },
  { path: "/Expense-Rule-mapping/:id", component: ExpenseRuleMapping },
  { path: "/permission", component: Permission },
  {
    path: "/Expense-Rule/Expense-Rule-mapping/add",
    component: ExpenseRuleMappingAdd,
  },
  { path: "/Expense-Rule-mapping/edit/:id", component: ExpenseRuleMappingEdit },
  { path: "/ExpenseDocument", component: ExpenseDocumentList }, //removed /:id temporarily
   //AccessoryReport
 { path: "/accessoryReport", component: EmployeeAccessoryList},
  //Salary
  { path: "/salary", component: SalaryList},
//
{ path: "/assetType", component: AssetTypeList},

{ path: "/change-password", component: UserChangePassword },
]

const userProtectedRoute = [

]

const publicRoutes = [
  { path: "/", component: Login },
  { path: "/applynow/:id", component: JobDetail },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:token", component: ResetPassword },
  // { path: "/register", component: Register },
  { path: "/reset-password", component: ChangePassword },
]

export { authProtectedRoutes, publicRoutes, userProtectedRoute }
