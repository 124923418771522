import { useState } from 'react';

const useRemark = () => {
  const [isShowingRemark, setIsShowingRemark] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleRemark() {
    setIsShowingRemark(!isShowingRemark);
    removeBodyCss();
  }

  return {
    isShowingRemark,
    toggleRemark,
  }
};

export default useRemark;